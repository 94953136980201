import React from "react";

import { PublicLayout } from "../components/layouts/PublicLayout";
import { AccountValidation } from "../containers/login/AccountValidation";

const PasswordRecoveryPage = () => {
    return (
        <PublicLayout>
            <AccountValidation recovery={true} />
        </PublicLayout>
    ) ;
}

export default PasswordRecoveryPage ;